<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>资讯动态</h3>
            <p>每一个新动态都是我们向前迈进的新脚步，每一次的发布是行业的<br />前进目标，每一条的新闻都是信息的挖掘！</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//新闻动态-->
    <div class="w news-detail">
        <h3 class="title">{{ title }}</h3>
        <div class="remark">
            <span>作者：{{ author }} </span>
            <span>发布：{{ created }} </span>
            <span><em v-for="(item,index) in tags" :key="index">{{ item }}</em></span>
        </div>
        <div class="desc">{{ description }}</div>
        <div class="thumb" v-show="thumb"><img :src="thumb" /></div>
        <div class="files" v-show="files">
            <span v-for="(item,index) in files" :key="index"><img :src="item.url" /></span>
        </div>
        <div class="article" v-html="content"></div>
        <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
    data: function(){
        return {
            author: "",
            cat: 0,
            content: "",
            created: "",
            description: "",
            files: false,
            id: 0,
            link: "",
            source: "",
            tags: [],
            thumb: false,
            title:''
        }
    },
    mounted: function(){
        var __this = this;
        var idx = this.$route.query.id;
        if(idx > 0)
        {
            $.post('https://crm.eqixing.cn/api/news/detail',{id: idx},ret=>{
                if(ret.code == 0)
                {
                    __this.author = ret.item && ret.item.author?ret.item.author:'';
                    __this.cat = ret.item && ret.item.cat?ret.item.cat:'';
                    __this.content = ret.item && ret.item.content?ret.item.content:'';
                    __this.created = ret.item && ret.item.created?ret.item.created:'';
                    __this.description = ret.item && ret.item.description?ret.item.description:'';
                    __this.files = ret.item && ret.item.files?ret.item.files:false;
                    __this.id = ret.item && ret.item.id?ret.item.id:'';
                    __this.link = ret.item && ret.item.link?ret.item.link:'';
                    __this.source = ret.item && ret.item.source?ret.item.source:'';
                    __this.tags = ret.item && ret.item.tags?ret.item.tags:'';
                    __this.thumb = ret.item && ret.item.thumb?ret.item.thumb:false;
                    __this.title = ret.item && ret.item.title?ret.item.title:'';
                }
            });
        }
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.news-detail{ padding: 50px 0px; min-height: 600px;}
.news-detail h3.title{ font-size: 24px;}
.news-detail .article{ line-height: 2.0; font-size: 1rem; padding: 30px 0px;}
.news-detail .remark{ line-height: 24px; padding: 15px 0px;}
.news-detail .remark span{ margin-right: 10px;}
.news-detail .remark span em{ background-color: #eeeeee; padding: 5px 10px; margin-right: 15px; font-style: normal;}
.news-detail .desc{ border: 1px #eeeeee dashed; padding: 20px;}
.news-detail .thumb{ text-align: center; padding: 20px;}
.news-detail .files{ padding: 20px; text-align: center;}
.news-detail .files span img{ max-width: 150px; border: 1px #eeeeee solid; padding: 5px;}
</style>
